import { Injectable } from '@angular/core';
import {
  PUSHER_SUBSCRIPTIONS,
  IWithdraw,
  IBet,
  IDraw,
  PUSHER_CHANNEL,
  WITHDRAW_STATE,
  ITelegramMessage,
} from '@libs/interfaces';
import { distinctUntilChanged, map, merge, Observable, of, Subject, switchMap, tap } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  addConnectedUsers,
  addNewDeposit,
  addNewUser,
  addNewWithdraw,
  addPendingWithdraw,
  removePendingWithdraw,
  updatePendingWithdraw,
} from '@libs/store/office/actions';
import { assign } from 'lodash';
import Pusher from 'pusher-js';
import { addNotification } from '@libs/store/notifications-office/actions';
import { OfficeStoreTelegramService } from '../store/office-store-telegram.service';
import { TelegramOfficeService } from './telegram/telegram-office.service';
Pusher.logToConsole = false;

@Injectable({
  providedIn: 'root',
})
export class PusherOfficeService {
  PUSHER_ID = 'facdb91483e6bf69b2e2';

  private channel;
  private channel_lotodobicho;
  withdrawCancelled = new Subject<IWithdraw>();
  withdrawApproved = new Subject();
  withdrawPending = new Subject();
  depositApproved = new Subject();
  depositCancelled = new Subject();
  depositPending = new Subject();
  newBet = new Subject<IBet>();
  removeBet = new Subject<IBet>();
  drawEvaluated = new Subject<IDraw>();
  affiliateVisitor = new Subject();
  userConnected = new Subject();
  userDisconnected = new Subject();
  userSignup = new Subject();
  numConnectedUsers = new Subject<number>();
  updatedBalance = new Subject<number>();
  constructor(
    private store: Store,
    private officeStoreTelegramService: OfficeStoreTelegramService,
    private telegramOfficeService: TelegramOfficeService,
  ) {
    const pusher = new Pusher(this.PUSHER_ID, { cluster: 'sa1' });
    this.channel = pusher.subscribe(PUSHER_CHANNEL);
    this.channel = pusher.subscribe('office');
    this.channel_lotodobicho = pusher.subscribe('lotodobicho');
  }
  addNotificationStore(element: any, type) {
    const notification = { element: element, type: type, show: false, timestamp: element.timestamp };
    this.store.dispatch(addNotification({ notification }));
  }
  init() {
    merge(
      this.subscribe(`${PUSHER_SUBSCRIPTIONS.DEPOSIT_APPROVED}`).pipe(
        tap((data: any) => this.depositApproved.next(data)),
        tap((notification: any) => this.addNotificationStore(notification, 'deposit')),
        tap(deposit => this.store.dispatch(addNewDeposit({ deposit }))),
      ),
      this.subscribe(`${PUSHER_SUBSCRIPTIONS.DEPOSIT_CANCELLED}`).pipe(
        tap((data: any) => this.depositCancelled.next(data)),
      ),
      this.subscribe(`${PUSHER_SUBSCRIPTIONS.DEPOSIT_PENDING}`).pipe(
        tap((data: any) => this.depositPending.next(data)),
      ),

      this.subscribe(`${PUSHER_SUBSCRIPTIONS.WITHDRAW_APPROVED}`).pipe(
        tap((withdraw: IWithdraw) => this.store.dispatch(removePendingWithdraw({ withdraw }))),
        tap((withdraw: IWithdraw) => this.withdrawApproved.next(withdraw)),
        tap(withdraw => this.store.dispatch(addNewWithdraw({ withdraw }))),
      ),
      this.subscribe(`${PUSHER_SUBSCRIPTIONS.WITHDRAW_CANCELLED}`).pipe(
        tap((withdraw: IWithdraw) => this.store.dispatch(removePendingWithdraw({ withdraw }))),
        tap((withdraw: IWithdraw) => this.withdrawCancelled.next(withdraw)),
      ),
      this.subscribe(`${PUSHER_SUBSCRIPTIONS.WITHDRAW_PENDING}`).pipe(
        tap((withdraw: IWithdraw) => this.store.dispatch(addPendingWithdraw({ withdraw }))),
        tap((withdraw: IWithdraw) => this.withdrawPending.next(withdraw)),
      ),
      this.subscribe(`${PUSHER_SUBSCRIPTIONS.WITHDRAW_PROCESING}`).pipe(
        map((withdraw: IWithdraw) => assign(withdraw, { state: WITHDRAW_STATE.SENDING })),
        tap((withdraw: IWithdraw) => this.store.dispatch(updatePendingWithdraw({ withdraw }))),
        tap((withdraw: IWithdraw) => this.withdrawPending.next(withdraw)),
      ),

      this.subscribe(`${PUSHER_SUBSCRIPTIONS.NEW_BET}`).pipe(tap((data: any) => this.newBet.next(data))),
      this.subscribe(`${PUSHER_SUBSCRIPTIONS.REMOVE_BET}`).pipe(tap((data: any) => this.removeBet.next(data))),
      this.subscribe(`${PUSHER_SUBSCRIPTIONS.AFFILIATE_VISITOR}`).pipe(
        tap((data: any) => this.affiliateVisitor.next(data)),
      ),
      this.subscribe(`${PUSHER_SUBSCRIPTIONS.USER_CONNECTED}`).pipe(tap((data: any) => this.userConnected.next(data))),
      this.subscribe(`${PUSHER_SUBSCRIPTIONS.USER_DISCONNECTED}`).pipe(
        tap((data: any) => this.userDisconnected.next(data)),
      ),
      this.subscribe(`${PUSHER_SUBSCRIPTIONS.USER_SIGNUP}`).pipe(
        tap((data: any) => this.userSignup.next(data)),
        tap((notification: any) => this.addNotificationStore(notification, 'user')),
        tap(() => this.store.dispatch(addNewUser())),
      ),
      this.subscribe(`${PUSHER_SUBSCRIPTIONS.USER_UPLOAD_DOCUMENT}`).pipe(
        tap(() => this.store.dispatch({ type: '[Office] Load pending users pending docs' })),
      ),
      this.subscribe_lotodobicho(`${PUSHER_SUBSCRIPTIONS.DRAW_EVALUATED}`).pipe(
        tap((data: any) => this.drawEvaluated.next(data)),
        tap((notification: any) => this.addNotificationStore(notification, 'draw')),
      ),
      this.subscribe(`${PUSHER_SUBSCRIPTIONS.CONNECTED_USERS}`).pipe(
        distinctUntilChanged(),
        tap((data: { connected: number }) => this.store.dispatch(addConnectedUsers(data))),
      ),
      this.subscribe(`${PUSHER_SUBSCRIPTIONS.TELEGRAM_MESSAGE}`).pipe(
        distinctUntilChanged(),
        tap((data: ITelegramMessage) => this.officeStoreTelegramService.addMessage(data)),
        switchMap((data: ITelegramMessage) => this.telegramOfficeService.analizeMessageUser(data)),
      ),
      this.subscribe(PUSHER_SUBSCRIPTIONS.TELEGRAM_USER_CONNECTED_CHANGE).pipe(
        tap((data: any) => this.officeStoreTelegramService.loadTelegramStats()),
      ),
    ).subscribe();
    return of(true);
  }
  subscribe(event: string) {
    return new Observable(observer => this.channel.bind(event, data => observer.next(data)));
  }
  subscribe_lotodobicho(event: string) {
    return new Observable(observer => this.channel_lotodobicho.bind(event, data => observer.next(data)));
  }
}
